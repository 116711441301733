































import { DueServices } from '@/services';
import { Component, Vue } from 'vue-property-decorator';
import { DueDocumentForm } from '@/components';
import { NavigationHelper } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: { DueDocumentForm }
})
export default class OnboardingDue extends Vue {
  public async downloadDUEHelp() {
    await DueServices.downloadDUEHelp();
  }

  public goBack() {
    NavigationHelper.goBack(this.$router);
  }
}
